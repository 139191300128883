<template>
  <QuestionnaireBase :form="form" :title="title" :finishFunction="null">
    <template v-slot:default>
      <component :is="show" :key="1" :ref="show" v-model="form"/>
      <component
          v-if="load in $options.components"
          v-show="false"
          :is="load"
          key="load"
          :ref="load"
          v-model="form"
      />
    </template>
  </QuestionnaireBase>
</template>

<script>
import {loadHelpers} from "../../loadHelpers";
import {dateFormat} from "@/mixins/dateFormat";

import QuestionnaireBase from "../../../../components/common/questionnaires/questionnaire";
import Business from "../../../../components/common/questionnaires/fieldsets/estatePlanningSurvey/Business";


export default {
  name: "EstatePlanningSurveySimpleBusiness",
  mixins: [loadHelpers, dateFormat],
  components: {
    QuestionnaireBase,
    Business
  },
  data() {
    return {
      name: 'EstatePlanningSurvey',
      title: 'Estate Planning Survey',
      url: 'estate_planning_survey',
      order:
          [
            'Business',
          ],
      expiryAccessCodeOnFinish: true
    }
  }
};
</script>
